"use client";
import Cookies from "js-cookie";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import logo from "../../public/images/login-logo.png";
import passwordIcon from "../../public/images/loginPassword.svg";
import user from "../../public/images/loginUser.svg";
import SpinnerLoad from "./components/common/SpinnerLoad";
import InputLogin from "./components/login/InputLogin";

export default function Home() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();

  const handleLogin = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_BASE_URL}/Login/Authenticate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );

    if (!response.ok) {
      setError("*Usuario o contraseña incorrectos");
      setIsLoading(false);
      return;
    }

    const data = await response.json();

    const resp = data.responseData;

    Cookies.set("token", resp.token);
    localStorage.setItem('logo', resp.logo)
    localStorage.setItem('username', email);
    localStorage.setItem("token", resp.token);
    router.push("/inicio");
  };

  const handleKeyPress = (e: { key: string; }) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  return (
    <div className="h-screen flex">
      <div className="w-1/2 flex-grow flex justify-center items-center bg-purple-900">
        <Image src={logo} alt="login" width={430} height={340} />
      </div>

      <div className="flex-grow w-1/2 bg-white flex justify-center items-center bg-purple-900">
        <div className="flex flex-col justify-center items-center shadow-lg p-10 rounded-xl bg-white">
          <InputLogin
            type="text"
            placeholder={"Usuario"}
            icon={user}
            width={20}
            height={20}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <InputLogin
            type="password"
            placeholder="Contraseña"
            icon={passwordIcon}
            width={16}
            height={20}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <button
            onClick={handleLogin}
            className="bg-purple-900 text text-white font-bold text-center p-2 border rounded w-64 h-12"
          >
            {isLoading ? <SpinnerLoad /> : "Entrar"}
          </button>
          {error && <div className="text-red-500">{error}</div>}
        </div>
      </div>
    </div>
  );
}
